import * as THREE from "three";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";

import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader.js";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";

import { LumaSplatsThree } from "@lumaai/luma-web";

// THREE.ColorManagement.enabled = true;

/**
 * DOM
 */
const canvas = document.querySelector("canvas.webgl");

const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

const aspectRatio = sizes.width / sizes.height;

/**
 * Scene camera and controls
 */
let scene = new THREE.Scene();

let camera = new THREE.PerspectiveCamera(50, aspectRatio, 1, 1500);
camera.position.x = -60;
camera.position.y = 180;
camera.position.z = 220;

let controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;

/**
 * Import Gaussian Splat Scene
 */
let splat = new LumaSplatsThree({
  source: "https://lumalabs.ai/capture/b318ae30-21fc-42f3-9561-4851ea7da8f9",
});

splat.rotateY(-Math.PI * 0.5);
splat.scale.set(100, 100, 100);
scene.add(splat);
console.log("Splats", splat);

/**
 * Import Custom Model
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 4.1);
scene.add(ambientLight);
//scene.fog = new THREE.Fog(0xcccccc, 500, 800);

const mtlLoader = new MTLLoader()
  .setPath("/models/")
  .load("PokeCenter.mtl", (materials) => {
    materials.preload();

    for (let materialName in materials.materials) {
      let material = materials.materials[materialName];
      //material.reflectivity = 0;
      //material.refractionRatio = 0;
      material.transparent = false;
    }

    console.log("Materials", materials.materials);

    const loader = new OBJLoader()
      .setMaterials(materials)
      .setPath("/models/")
      .load("PokeCenter.obj", (model) => {
        model.rotateY(Math.PI * 0.2);
        model.translateY(22);
        model.translateX(-5);
        model.scale.set(1, 0.75, 1);
        scene.add(model);
      });
  });

/**
 * Render and Loop
 */

const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
});

renderer.render(scene, camera);
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(2, window.devicePixelRatio));

window.addEventListener("resize", () => {
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  renderer.setSize(sizes.width, sizes.height);
});

const loop = () => {
  controls.update();
  renderer.render(scene, camera);
  renderer.setSize(sizes.width, sizes.height, true);
  window.requestAnimationFrame(loop);
};

loop();
